import React from 'react'

import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page Not Found" />
    <article>
      <div className="c-content">
        <div className="container">
          <div className="row">
            <div className="c-content__content-container">
              <div className="row">
                <div className="c-content__title-container">
                  <h2 className="c-content__title">Page Not Found</h2>
                </div>
                <div className="c-content__summary-container cms-content">
                  <p>This might be because:</p>
                  <ul>
                    <li>You may have typed the web address incorrectly. Please
                      check the address and spelling ensuring that it does not
                      contain capital letters or spaces.
                    </li>
                    <li>It is possible that the page you were looking for may
                      have been moved, updated or deleted.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </Layout>
)

export default NotFoundPage
